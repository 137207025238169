import React, { Fragment } from "react";

import Layout from "../components/layout";
import SignInForm, {
  SignInGoogle,
  SignInTwitter,
} from "../components/Account/SignIn";
import { SignUpLink } from "../components/Account/SignUp";
import { PasswordForgetLink } from "../components/Account/PasswordForget";

const SignInPage = () => (
  <Fragment>
    <h1>Inloggen</h1>
    <SignInForm />
    <PasswordForgetLink />
    <SignUpLink />
    {/* <hr />
    <SignInGoogle />
    <SignInTwitter /> */}
  </Fragment>
);

export default ({ location }) => (
  <Layout location={location}>
    <SignInPage />
  </Layout>
);
